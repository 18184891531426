@import "./src/theme/scss/variables.scss";

.custom-input {
  .label {
    font-size: 12px;
    line-height: 16px;
  }
  .input {
    height: 40px;
    font-size: 12px;
    border-radius: 8px;
    background-color: $white-16;
    transition: all 0.3s ease-in-out;

    &:not(.disabled-input):not(.mobile-input):hover {
      background-color: $white-24;
    }

    &:not(.disabled-input):focus {
      background-color: $white-24;
    }

    &.disabled-input {
      cursor: not-allowed;
      border: 1px solid $white-24 !important;
      background-color: $white-16;
    }
  }

  .input-hint-text {
    font-size: 12px;
    line-height: 16px;
  }
}

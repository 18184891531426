@import "src/theme/scss/variables.scss";

.auth-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: $dark-light;

  .modal-logo {
    top: 20px;
    left: 20px;
  }

  .modal-container {
    z-index: 100;
    overflow-y: auto;

    width: 335px;
    min-width: 250px;
    max-width: 450px;

    border-radius: $spacer;
    background-color: $dark;

    @media (max-width: 575px) {
      &.is-portrait {
        width: 90%;
        height: auto;
        max-height: 90%;
      }
    }

    &.is-mobile {
      width: 90%;
      height: auto;
      max-height: 90%;
    }
  }
}

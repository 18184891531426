.auth-error {
  .error-title {
    font-size: 12px;
    line-height: 14px;
  }

  .error-close-button {
    width: 32px;
  }
}

@import "src/theme/scss/variables.scss";

.friend-sound-control {
  flex: 1;
  height: 55px;
  display: flex;
  pointer-events: all;

  & > .control-container {
    flex: 1;
    display: flex;
    margin-left: 12px;
    padding: 0 16px 2px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: $dark-80;
    border: 1.5px solid $white-08;

    & > .control-title-container {
      margin-bottom: 2px;

      & > .control-title {
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }
}

@import "./src/theme/scss/variables.scss";

.custom-switcher {
  width: 48px;
  border-radius: 32px;
  height: 28px !important;
  transition: background-color 0.3s ease-in-out;

  &.default-button {
    background-color: $white-20;
  }

  .switcher-container {
    width: 48px;
    height: 28px;
    padding: 0 7px;

    .switcher-pin {
      top: 4px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }
}

@import "./src/theme/scss/variables.scss";

.icon-button {
  width: 48px;
  border-radius: 24px;

  &.active {
    background-color: $white-48;
  }
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Regular"),
    url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Black"), url("../fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Bold"), url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-ExtraBold"),
    url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-ExtraLight"),
    url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Light"), url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Medium"),
    url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-SemiBold"),
    url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("../fonts/Inter/Inter-Thin"), url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

html, body, #root {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

#root {
  overflow: visible;
}

.root-portal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
}
